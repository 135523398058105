import React from 'react';
import { NextPage } from 'next';
import styled from 'styled-components';

import { Box, Divider } from '@hero-design/react';

import { mediaMaxQueries } from '@packages/hero-theme/utils';
import { InitFetchData } from '@shared/utils';

import {
  CountriesList,
  JobsList,
  RecruitmentSettings,
  TeamList,
} from '../../types';

import CompanyInformation from './CompanyInformation';
import Header from './Header';
import RoleListContainer from './RoleListContainer';

const Container = styled.div`
  width: 80vw;
  padding: ${({ theme }) => theme.space.medium}px;
  margin: ${({ theme }) => theme.space.xxxxlarge}px 0;

  border-radius: ${({ theme }) => theme.radii.medium}px;
  border: ${({ theme }) =>
    `${theme.borderWidths.base}px solid ${theme.colors.defaultBorder}`};

  ${mediaMaxQueries.md} {
    width: 90vw;
    margin-top: ${({ theme }) => theme.space.medium}px;
  }
`;

type DefaultLiftedCareerPageDetailProps = {
  orgId: string;
  jobs: InitFetchData<JobsList>;
  teams: InitFetchData<TeamList>;
  countries: InitFetchData<CountriesList>;
  recruitmentSettings: InitFetchData<RecruitmentSettings>;
};

const DefaultLiftedCareerPageDetail: NextPage<
  DefaultLiftedCareerPageDetailProps
> = ({ orgId, jobs, recruitmentSettings, teams, countries }) => (
  <Container data-test-id={`default-career-page-${orgId}`}>
    <Header recruitmentLogo={recruitmentSettings?.data?.recruitment_logo} />

    <Box sx={{ ml: '5%', width: '90%' }}>
      <CompanyInformation recruitmentSettingsData={recruitmentSettings?.data} />
      <Divider marginY="large" />

      <RoleListContainer
        jobs={jobs}
        orgId={orgId}
        teams={teams}
        countries={countries}
        recruitmentSettings={recruitmentSettings}
      />
    </Box>
  </Container>
);

export default DefaultLiftedCareerPageDetail;
