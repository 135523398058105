import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Box, Button, Modal, Typography } from '@hero-design/react';

import {
  emailPattern,
  requiredFieldNotEmpty,
  requiredMaxLength,
} from '@packages/eh-utils/formValidators';
import { TextInput } from '@packages/hero-theme/form';

import { TDetailsForm } from './type';

const DetailsFormModal = ({
  onClose,
  handleTracking,
  open,
  isLoading,
  isFromExp2,
}: {
  onClose: () => void;
  handleTracking: (values: TDetailsForm) => void;
  open: boolean;
  isLoading?: boolean;
  isFromExp2?: boolean;
}) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    contactNo: '',
    workEmail: '',
  };

  const formMethods = useForm<TDetailsForm>({
    mode: 'all',
    defaultValues: initialValues,
  });

  const onSubmit = (values: TDetailsForm) => {
    handleTracking(values);
  };

  return (
    <Modal
      title={
        isFromExp2
          ? 'Unlock your next candidate'
          : 'Claim your business account'
      }
      open={open}
      variant="primary"
      onClose={onClose}
      body={
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Typography.Text sx={{ mb: 'medium' }}>
            {isFromExp2
              ? 'Your ideal candidate is just a step away. Fill out the form below to claim and connect with them instantly.'
              : 'Please fill out the following details to access your account:'}
          </Typography.Text>
          <Controller<TDetailsForm, 'firstName'>
            name="firstName"
            control={formMethods.control}
            render={({ field, fieldState }) => (
              <TextInput
                field={field}
                fieldState={fieldState}
                inputProps={{
                  placeholder: 'First name',
                }}
                labelProps={{
                  text: 'First name',
                  required: true,
                }}
              />
            )}
            rules={{
              validate: {
                requiredFieldNotEmpty,
                requiredMaxLength: requiredMaxLength(30),
              },
            }}
          />

          <Controller<TDetailsForm, 'lastName'>
            name="lastName"
            control={formMethods.control}
            render={({ field, fieldState }) => (
              <TextInput
                field={field}
                fieldState={fieldState}
                inputProps={{
                  placeholder: 'Last name',
                }}
                labelProps={{
                  text: 'Last name',
                  required: true,
                }}
              />
            )}
            rules={{
              validate: {
                requiredFieldNotEmpty,
                requiredMaxLength: requiredMaxLength(30),
              },
            }}
          />

          <Controller<TDetailsForm, 'contactNo'>
            name="contactNo"
            control={formMethods.control}
            render={({ field, fieldState }) => (
              <TextInput
                field={field}
                fieldState={fieldState}
                inputProps={{
                  placeholder: 'Contact number',
                }}
                labelProps={{
                  text: 'Contact number',
                }}
              />
            )}
          />

          <Controller<TDetailsForm, 'workEmail'>
            name="workEmail"
            control={formMethods.control}
            render={({ field, fieldState }) => (
              <TextInput
                field={field}
                fieldState={fieldState}
                inputProps={{
                  placeholder: 'Work email',
                }}
                labelProps={{
                  text: 'Work email',
                  required: true,
                }}
              />
            )}
            rules={{
              validate: {
                requiredFieldNotEmpty,
                emailPattern,
              },
            }}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button text="Submit" type="submit" loading={isLoading} />
          </Box>
        </form>
      }
    />
  );
};

export default DetailsFormModal;
