import { useMemo } from 'react';
import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import {
  DataWrapper,
  ExtendedErrorData,
  InitFetchData,
  RecruitmentSettings,
} from '../types';

const useFetchRecruitmentSettings = (
  orgId: string,
  initData?: InitFetchData<RecruitmentSettings>,
  configs?: SWRConfiguration
) => {
  const { fetcher } = useFetch<
    DataWrapper<RecruitmentSettings>,
    void,
    ExtendedErrorData
  >({
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/career_page/organisations/${orgId}/recruitment_settings`,
    method: 'GET',
    withSWR: true,
  });
  const initFetchData = initData?.data;
  // normalize to match with data shape of fetch
  const initOrgData = useMemo(
    () => initFetchData && { data: initFetchData },
    [initFetchData]
  );

  const {
    data,
    mutate: fetchRecruitmentSettings,
    error,
  } = useSWR<DataWrapper<RecruitmentSettings>, ExtendedErrorData>(
    orgId
      ? {
          key: `organisationRecruitmentSettings_${orgId}`,
        }
      : null,
    () => fetcher(),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...configs,
    }
  );

  return {
    recruitmentSettings: data || initOrgData,
    fetchRecruitmentSettings,
    isFetchingRecruitmentSettings: !data && !error && !initOrgData,
  };
};

export default useFetchRecruitmentSettings;
