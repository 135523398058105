import { useMemo } from 'react';
import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import {
  CountriesList,
  DataWrapper,
  ErrorData,
  InitFetchData,
} from '../types/index';

const useFetchAllCountries = (
  configs: SWRConfiguration = {},
  initCountries?: InitFetchData<CountriesList>
) => {
  const shouldFetchOnMount = !initCountries || !!initCountries?.errorCode;
  const initFetchData = initCountries?.data;
  const initCountriesData = useMemo(
    () => initFetchData && { data: initFetchData },
    [initFetchData]
  );

  const { fetcher } = useFetch<DataWrapper<CountriesList>, void, ErrorData>({
    method: 'GET',
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/countries`,
  });

  const {
    data,
    mutate: fetchCountriesData,
    error: countriesError,
  } = useSWR<DataWrapper<CountriesList>, ErrorData>(
    { key: 'atsFetchAllCountries' },
    () => fetcher(),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: shouldFetchOnMount,
      ...configs,
    }
  );

  return {
    rawCountriesData: data || initCountriesData,
    fetchCountriesData,
    countriesError,
    isLoadingCountries: !initCountriesData && !data && !countriesError,
  };
};

export default useFetchAllCountries;
