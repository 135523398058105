import concat from 'lodash/fp/concat';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import identity from 'lodash/fp/identity';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';
import __ from 'lodash/fp/placeholder';
import remove from 'lodash/fp/remove';
import slice from 'lodash/fp/slice';

import React, { useCallback } from 'react';

import { Checkbox, Divider, Spinner, Typography } from '@hero-design/react';

import { InputChangeHandler } from '@shared/types';

import {
  SelectedValue,
  SelectOption,
  SelectOptions,
} from '../../modules/CareersPage/types';

export const OTHER_OPTION: SelectOption = {
  value: 'other',
  text: 'Other',
};

const calcIndeterminateState = (
  selectedValues?: SelectedValue[],
  options?: SelectOptions
) => {
  if (selectedValues == null || options == null) {
    return false;
  }

  return selectedValues.length !== 0 && selectedValues.length < options.length;
};

const calcCheckedState = (
  selectedValues?: SelectedValue[],
  options?: SelectOptions
) => {
  if (selectedValues == null || options == null) {
    return false;
  }

  return (
    selectedValues.length !== 0 && selectedValues.length === options.length
  );
};

export const formatFilterValue = (
  selectedValues?: SelectedValue[],
  options?: SelectOptions
) => {
  if (selectedValues == null || options == null) {
    return '';
  }

  const showAllText =
    (selectedValues && options && selectedValues.length === 0) ||
    selectedValues.length === options.length;
  const moreThanTwoSelectedValue = selectedValues.length > 2;

  if (showAllText) {
    return 'All';
  }

  const appliedFilter = flow(
    filter((item: SelectOption) => selectedValues.indexOf(item.value) !== -1),
    map('text'),
    slice(0, 2),
    moreThanTwoSelectedValue ? concat(__, '...') : identity,
    join(', ')
  )(options);

  return appliedFilter;
};

type BaseFilterProps = {
  dataTestId?: Partial<{ target: string; content: string }>;
  loading?: boolean;
  options: SelectOptions;
  value?: SelectedValue[];
  onChange: (allValues: SelectedValue[]) => void;
};

const BaseFilter = ({
  dataTestId,
  loading,
  options,
  onChange,
  value,
}: BaseFilterProps) => {
  const hasOptions = !isEmpty(options);
  const onCheckOtherChange = useCallback<InputChangeHandler>(
    e => {
      if (value == null) return;
      const newValues: SelectedValue[] = e.target.checked
        ? concat(value)(OTHER_OPTION.value)
        : remove((item: SelectedValue) => item === OTHER_OPTION.value)(value);

      onChange(newValues);
    },
    [onChange, value]
  );
  const onCheckAllChange = React.useCallback<InputChangeHandler>(
    e => {
      if (e.target.checked) {
        const newValues = map('value')(options);

        onChange(newValues);
      } else {
        onChange([]);
      }
    },
    [options, onChange]
  );
  const onCheckChange = React.useCallback(
    (newValue: SelectedValue[]) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <Spinner loading={loading} size="small">
      <div
        data-test-id={dataTestId?.content}
        style={{
          maxHeight: 250,
          overflowY: 'auto',
        }}
      >
        {hasOptions ? (
          <>
            <Checkbox
              text="Show all"
              value="all"
              indeterminate={calcIndeterminateState(value, options)}
              checked={calcCheckedState(value, options)}
              onChange={onCheckAllChange}
            />

            <Divider marginY="small" />

            <Checkbox.Group
              value={value ?? []}
              options={remove(OTHER_OPTION, options)}
              onChange={onCheckChange}
            />

            {includes(OTHER_OPTION)(options) && (
              <>
                <Divider marginY="small" />

                <Checkbox
                  value={OTHER_OPTION.value}
                  text={OTHER_OPTION.text}
                  checked={includes(OTHER_OPTION.value, value)}
                  onChange={onCheckOtherChange}
                />
              </>
            )}
          </>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Typography.Text>No Data</Typography.Text>
          </div>
        )}
      </div>
    </Spinner>
  );
};

export default BaseFilter;
