import { useMemo } from 'react';
import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import {
  CountriesList,
  DataWrapper,
  ExtendedErrorData,
  InitFetchData,
} from '../types/index';

const useFetchCountriesListByOrgId = (
  orgId: string,
  initCountries?: InitFetchData<CountriesList>,
  configs?: SWRConfiguration
) => {
  const { fetcher } = useFetch<
    DataWrapper<CountriesList>,
    void,
    ExtendedErrorData
  >({
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/career_page/organisations/${orgId}/countries`,
    method: 'GET',
    withSWR: true,
  });
  const initFetchData = initCountries?.data;
  // normalize to match with data shape of fetch
  const initCountriesData = useMemo(
    () => initFetchData && { data: initFetchData },
    [initFetchData]
  );

  const {
    data,
    error: countriesError,
    mutate: fetchJobCountriesData,
  } = useSWR<DataWrapper<CountriesList>, ExtendedErrorData>(
    orgId ? { key: `countriesByOrganisation_${orgId}` } : null,
    () => fetcher(),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...configs,
    }
  );

  return {
    jobCountriesData: data || initCountriesData,
    countriesError,
    fetchJobCountriesData,
    isLoading: !initCountriesData && !data && !countriesError,
  };
};

export default useFetchCountriesListByOrgId;
