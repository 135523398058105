import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Input } from '@hero-design/react';

import { makeDebounceAction } from '@shared/utils/debounceAction';

import { onFilterChangeSWR } from '../../utils/helpers';
import { QueryParams } from '../../types';

type SearchRoleProps = {
  queryParams: QueryParams;
  setQueryParams: (newState: React.SetStateAction<QueryParams>) => void;
  setCurrentSelectedPage: (newState: number) => void;
};

const debounceAction = makeDebounceAction(300);

const SearchRoleBar = ({
  setQueryParams,
  queryParams,
  setCurrentSelectedPage,
}: SearchRoleProps) => {
  const [value, setValue] = useState('');
  // TODO: replace value by debouncedValue
  // const debouncedValue = useDebounce(value, 500);
  const [isFirstTimeInput, setIsFirstTimeInput] = useState(true);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    debounceAction(() => {
      setValue(e.target.value);
      setIsFirstTimeInput(false);
    });
  }, []);

  useEffect(() => {
    if (!isFirstTimeInput) {
      setCurrentSelectedPage(1);
      onFilterChangeSWR({
        queryParams,
        setQueryParams,
      })({ query: value });
    }
  }, [value, isFirstTimeInput]);

  return (
    <Input
      placeholder="Search by role title"
      prefix="search-outlined"
      onChange={handleChange}
    />
  );
};

export default SearchRoleBar;
