import getVisitorToken from '@shared/utils/getVisitorTokenTracking';

import { Nullable } from 'src/modules/ReferenceCheck/common/types';
import { TUtmData } from 'src/modules/User/types';

import { MatchCandidateItem } from '../../CYCCareersPageDetail/CycListCandidate/type';

import { TDetailsForm } from './type';

export const prepareTrackingParams = ({
  distinctId,
  userDetails,
  eventName,
  userUniqueId,
  utmData,
  orgId,
  userIp,
  candidateData,
  industry,
  clickArea,
}: {
  distinctId: string;
  userDetails?: TDetailsForm | null;
  eventName: string;
  userUniqueId?: string;
  utmData?: TUtmData;
  userIp?: string | null;
  orgId: string;
  candidateData?: Nullable<MatchCandidateItem>;
  industry?: Nullable<string>;
  clickArea?: Nullable<string>;
}) => ({
  name: eventName,
  landing_page: window.location.href,
  user_agent: window.navigator.userAgent,
  referrer: document.referrer,
  properties: {
    mixpanel_distinct_id: distinctId,
    user_details: userDetails || {},
    orgId,
    industry,
    click_area: clickArea,
    candidate_data: candidateData || {},
  },
  visitor_token: getVisitorToken(),
  ...(userUniqueId ? { user_uuid: userUniqueId } : {}),
  ...(utmData || {}),
  ...(userIp ? { ip: userIp } : {}),
});
