import { useMemo } from 'react';
import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import {
  DataWrapper,
  ExtendedErrorData,
  InitFetchData,
  TeamList,
} from '../types/index';

const useFetchTeamsListByOrgId = (
  orgId: string,
  initData?: InitFetchData<TeamList>,
  configs?: SWRConfiguration
) => {
  const { fetcher } = useFetch<DataWrapper<TeamList>, void, ExtendedErrorData>({
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/career_page/organisations/${orgId}/teams`,
    method: 'GET',
    withSWR: true,
  });
  const initFetchData = initData?.data;
  // normalize to match with data shape of fetch
  const initTeamsData = useMemo(
    () => initFetchData && { data: initFetchData },
    [initFetchData]
  );

  const {
    data,
    error: teamsError,
    mutate: fetchTeamsData,
  } = useSWR<DataWrapper<TeamList>, ExtendedErrorData>(
    orgId ? { key: `teamsByOrganisation_${orgId}` } : null,
    () => fetcher(),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...configs,
    }
  );

  return {
    teamsData: data || initTeamsData,
    teamsError,
    fetchTeamsData,
    isLoading: !initTeamsData && !data && !teamsError,
  };
};

export default useFetchTeamsListByOrgId;
