import isEmpty from 'lodash/fp/isEmpty';

import React from 'react';

import { Radio, Spinner, Typography } from '@hero-design/react';

import { SelectedValue, SelectOptions } from '../../modules/CareersPage/types';

type BaseFilterProps = {
  dataTestId?: Partial<{ target: string; content: string }>;
  loading?: boolean;
  options: SelectOptions;
  value: SelectedValue;
  onChange: (value: SelectedValue) => void;
  commit: (v: SelectedValue) => void;
  closeDropdown: () => void;
};

const RadioBaseFilter = ({
  dataTestId,
  loading,
  options,
  onChange,
  value,
  commit,
  closeDropdown,
}: BaseFilterProps) => {
  const hasOptions = !isEmpty(options);

  const onCheckChange = React.useCallback(
    (newValue: string | number) => {
      onChange(newValue);
      commit(newValue);
      closeDropdown();
    },
    [onChange, commit]
  );

  return (
    <Spinner loading={loading} size="small">
      <div
        data-test-id={dataTestId?.content}
        style={{
          maxHeight: 250,
          overflowY: 'auto',
        }}
      >
        {hasOptions ? (
          <Radio.Group
            value={value}
            options={options}
            onChange={onCheckChange}
          />
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Typography.Text>No Data</Typography.Text>
          </div>
        )}
      </div>
    </Spinner>
  );
};

export default RadioBaseFilter;
