import { MatchCandidateItem } from './type';

export const AVATAR_SIZE = 50;
export const DUMMY_CANDIDATE: MatchCandidateItem = {
  id: 'a186b2fa-6770-4191-99ec-ded11d56567f',
  first_name: 'Leyla',
  last_name: 'Salvador',
  summary: null,
  avatar_url: null,
  current_job_title: 'Food And Beverage Attendant',
  current_company: 'Misc Parramatta',
  experience: 0,
  friendly_id: 'leyla-salvador',
  salary_info: null,
  availability_to_start: null,
  work_type: null,
  eligible_for_work: true,
  vendor_location_name: 'Haymarket',
  distance_from_org: 1,
  maybe_approachable: true,
  certifications: 'RSA',
  country: 'AU',
};

export const LIST_CANDIDATES: MatchCandidateItem[] = [
  {
    id: 'a186b2fa-6770-4191-99ec-ded11d56567f',
    first_name: 'Leyla',
    last_name: 'Salvador',
    summary:
      'Highly skilled Food and Beverage Attendant with a proven track record of providing exceptional customer service in both retail and hospitality settings. Experienced in creating a welcoming atmosphere for customers and assisting them with their inquiries and product needs. Demonstrated proficiency in managing financial transactions accurately and maintaining meticulous records. Known for delivering outstanding customer service in fast-paced, customer-focused environments. Currently seeking opportunities to leverage my skills and expertise in a front of house role.',
    avatar_url: '/assets/images/ava-food-and-beverage.png',
    current_job_title: 'Food And Beverage Attendant',
    current_company: 'Misc Parramatta',
    experience: 0,
    friendly_id: 'leyla-salvador',
    salary_info: null,
    availability_to_start: null,
    work_type: null,
    eligible_for_work: true,
    vendor_location_name: 'Haymarket',
    distance_from_org: 1,
    maybe_approachable: true,
    certifications: 'RSA',
    country: 'AU',
  },
  {
    id: '7c8ed246-630d-46d0-9eb6-dbbe395af2f3',
    first_name: 'Gonzalez',
    last_name: 'Maria',
    summary:
      'I am an international student. I have experience in commercial and domestic cleaning. Safely handle chemicals and work activities. I have skills such as great attention to detail, responsible and I like to learn new things. I am a great team member and can also work autonomously. I am strengthening my communication skills and I am currently at an Intermediate level of English',
    avatar_url: '',
    current_job_title: 'Cleaner',
    current_company: 'private domestic cleaner',
    experience: 9,
    friendly_id: 'gonzalez-maria',
    salary_info: {
      currency: 'AUD',
      minimum: 29.0,
      maximum: 29.0,
      frequency: 'hour',
    },
    availability_to_start: 'immediately',
    work_type: 'Part Time',
    eligible_for_work: true,
    vendor_location_name: 'Glen Waverley',
    distance_from_org: 703,
    maybe_approachable: false,
    certifications: null,
    country: 'AU',
  },
  {
    id: '5f7d969d-41fc-4cf0-b107-2aef3d3bd3dd',
    first_name: 'Efkan',
    last_name: 'Varol',
    summary: '',
    avatar_url: '',
    current_job_title: 'Kitchen Hand',
    current_company: 'College Settlemen',
    experience: 2,
    friendly_id: 'efkan-varol',
    salary_info: {
      currency: 'AUD',
      minimum: 25.0,
      maximum: 42.0,
      frequency: 'hour',
    },
    availability_to_start: 'flexible',
    work_type: 'Full time',
    eligible_for_work: true,
    vendor_location_name: 'Darlinghurst',
    distance_from_org: 1,
    maybe_approachable: false,
    certifications: null,
    country: 'AU',
  },
];
