import { Box, Pagination } from '@hero-design/react';

import { DEFAULT_ITEM_PER_PAGE } from 'src/constants';

type PaginationSectionProps = {
  totalPages: number;
  totalItems: number;
  currentSelectedPage: number;
  onChangePage: (selectedPage: number) => void;
};

const PaginationSection = ({
  totalPages,
  totalItems,
  onChangePage,
  currentSelectedPage,
}: PaginationSectionProps) => {
  if (totalItems <= DEFAULT_ITEM_PER_PAGE) {
    return null;
  }

  return (
    <Box
      mt="large"
      mb="small"
      sx={{ display: 'flex', justifyContent: 'flex-end' }}
    >
      <Pagination
        total={totalPages}
        onChange={onChangePage}
        current={currentSelectedPage}
      />
    </Box>
  );
};

export default PaginationSection;
