import { v4 as uuid } from 'uuid';

const getVisitorToken = () => {
  const storageKey = 'visitorToken';

  // eslint-disable-next-line immutable/no-let
  let token = localStorage.getItem(storageKey);

  if (!token) {
    token = uuid();
    localStorage.setItem(storageKey, token);
  }

  return token;
};

export default getVisitorToken;
