import { OTHER_OPTION } from '@shared/MultiSelectBaseFilter';

export const EmploymentTypeOptions = [
  {
    text: 'Full-time',
    value: 'full_time',
  },
  {
    text: 'Part-time',
    value: 'part_time',
  },
];

export const WorkplaceTypeOptions = [
  {
    text: 'Remote',
    value: 'remote',
  },
  {
    text: 'Hybrid',
    value: 'hybrid',
  },
  {
    text: 'On-site',
    value: 'onsite',
  },
];

export const ExperienceLevelsOptions = [
  {
    text: 'Internship',
    value: 'internship',
  },
  {
    text: 'Entry level',
    value: 'entry_level',
  },
  {
    text: 'Associate',
    value: 'associate',
  },
  {
    text: 'Middle senior',
    value: 'mid_senior',
  },
  {
    text: 'Director',
    value: 'director',
  },
  {
    text: 'Executive',
    value: 'executive',
  },
  OTHER_OPTION,
];

export const SalaryOptions = [
  {
    text: 'Any',
    value: '0',
  },
  {
    text: '$25,000+',
    value: '25000',
  },
  {
    text: '$50,000+',
    value: '50000',
  },
  {
    text: '$75,000+',
    value: '75000',
  },
  {
    text: '$100,000+',
    value: '100000',
  },
  {
    text: '$125,000+',
    value: '125000',
  },
  {
    text: '$150,000+',
    value: '150000',
  },
  {
    text: '$175,000+',
    value: '175000',
  },
  {
    text: '$200,000+',
    value: '200000',
  },
];
