import React from 'react';
import styled from 'styled-components';

import { Box, Divider, theme, Typography } from '@hero-design/react';

import { mediaMaxQueries } from '@packages/hero-theme/utils';
import OrgLogo from '@shared/OrgLogo';

import { RecruitmentSettings } from '../../types';

const CompanyInfoWrapper = styled(Box)`
  flex: 1;
  display: flex;

  ${mediaMaxQueries.md} {
    flex-direction: column;

    div:first-child {
      margin-bottom: ${theme.space.medium}px;
    }
  }
`;

type OverviewProps = {
  totalJobs: number;
  data: RecruitmentSettings;
};

const Overview = ({ data, totalJobs }: OverviewProps) => (
  <>
    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
      {data?.recruitment_logo && (
        <OrgLogo orgLogoUrl={data.recruitment_logo.logo_url} />
      )}

      <CompanyInfoWrapper>
        <Box>
          {data?.company_name && (
            <Typography.Title level={1}>{data.company_name}</Typography.Title>
          )}

          {data?.company_industry && (
            <Typography.Text intent="subdued" tagName="p">
              {data.company_industry}
            </Typography.Text>
          )}
        </Box>
      </CompanyInfoWrapper>
    </Box>

    <Divider />

    <Box mt="xxxlarge">
      <Typography.Title level={5}>Company Overview</Typography.Title>

      {data?.company_overview && (
        <Box mt="small">
          <Typography.Text intent="body" tagName="p">
            {data.company_overview}
          </Typography.Text>
        </Box>
      )}
    </Box>

    <Box mt="xlarge">
      <Typography.Text fontSize={16}>
        <b>{totalJobs}</b> {totalJobs === 1 ? 'job' : 'jobs'} currently
        available at <b>{data.company_name}</b>
      </Typography.Text>
    </Box>
  </>
);

export default Overview;
