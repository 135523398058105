import styled, { useTheme } from 'styled-components';

import { Box, Divider, Icon, Tag, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { mediaQueries } from '@packages/hero-theme/utils';

import { RecruitmentSettings } from 'src/modules/CareersPage/types';

const Wrapper = styled.div`
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }

  width: 100%;
  margin: ${({ theme }) => theme.space.large}px;
  margin-top: ${({ theme }) => theme.space.large}px;
  margin-bottom: 0;

  display: flex;
  flex-direction: column;

  padding: 0 ${({ theme }) => theme.space.xxxlarge}px;
  padding-top: ${({ theme }) => theme.space.large}px;
  padding-bottom: ${({ theme }) => theme.space.medium}px;
  gap: ${({ theme }) => theme.space.medium}px;
`;

const InfoItem = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.small}px;
`;

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.medium}px;

  ${mediaQueries.lg} {
    flex-direction: row;
    justify-items: space-between;
  }
`;

const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.medium}px;
  flex-wrap: wrap;
`;

const IconWrapper = styled.div`
  flex: 0 0 20px;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
`;

const CompanyLogoWrapper = styled.div`
  width: ${({ theme }) => theme.sizes.xxlarge}px;
  height: ${({ theme }) => theme.sizes.xxlarge}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PoweredByEH = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: theme.space.small,
        flex: 0,
      }}
    >
      <Typography.Text
        tagName="span"
        sx={{
          whiteSpace: 'nowrap',
          fontStyle: 'italic',
        }}
      >
        Powered by
      </Typography.Text>
      <Image
        src="/assets/images/eh-logo-black.svg"
        alt="Employment Hero"
        sx={{
          height: theme.sizes.large,
          width: 'auto',
        }}
      />
    </Box>
  );
};

export type ProfileProps = {
  orgFriendlyId: string;
  recruitmentSettings: RecruitmentSettings;
};
const Profile = ({ recruitmentSettings, orgFriendlyId }: ProfileProps) => {
  const theme = useTheme();

  const industry = recruitmentSettings.company_industry;
  const logoUrl = recruitmentSettings.recruitment_logo?.logo_url;
  const cycUrlWithoutProtocol = `${window.location.host}/organisations/${orgFriendlyId}`;

  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.space.medium,
        }}
      >
        {logoUrl && (
          <CompanyLogoWrapper>
            <Image
              src={logoUrl}
              alt={recruitmentSettings.company_name}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </CompanyLogoWrapper>
        )}

        <Typography.Title level={1}>
          {recruitmentSettings.company_name}
        </Typography.Title>
      </Box>

      <CompanyInfo>
        <InfoWrapper>
          <InfoItem>
            <IconWrapper>
              <Icon icon="double-buildings-outlined" size="xlarge" />
            </IconWrapper>
            <Typography.Text tagName="span">
              {industry || 'Hospitality'}
            </Typography.Text>
          </InfoItem>

          <InfoItem>
            <IconWrapper>
              <Icon icon="location-outlined" size="xlarge" />
            </IconWrapper>
            <Typography.Text tagName="span">
              {recruitmentSettings.company_city || 'Australia'}
            </Typography.Text>
          </InfoItem>

          <InfoItem
            sx={{
              maxWidth: '100%',
            }}
          >
            <IconWrapper>
              <Icon icon="link-2" size="xlarge" />
            </IconWrapper>
            <Typography.Text
              tagName="span"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {cycUrlWithoutProtocol}
            </Typography.Text>
            <Tag
              variant="outlined"
              intent="primary"
              text="DEMO PAGE ONLY"
              sx={{
                background: theme.colors.palette.violetLight90,
                color: theme.colors.palette.violetLight15,
                border: `1px solid ${theme.colors.palette.violetLight15}`,
                whiteSpace: 'nowrap',
              }}
            />
          </InfoItem>
        </InfoWrapper>

        <PoweredByEH />
      </CompanyInfo>

      <Divider />
    </Wrapper>
  );
};

export default Profile;
