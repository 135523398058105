import { PossibleFilterValueItem } from '@hero-design/react';

import { SelectOptions } from 'src/modules/CareersPage/types';

export const tagValueRendererCreator =
  (options: SelectOptions) =>
  <T extends { value?: PossibleFilterValueItem }>({ value }: T) => {
    if (!value || (Array.isArray(value) && value?.length === 0)) return '';
    const arrayValue = Array.isArray(value) ? value : [value];
    const labels = arrayValue.map(v => options.find(o => o.value === v)?.text);

    return labels.filter(Boolean).join(', ');
  };
