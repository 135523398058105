import React from 'react';
import styled from 'styled-components';

import { swagPalette } from '@hero-design/colors';
import { Box, Button, Typography } from '@hero-design/react';

import { mediaQueries } from '@packages/hero-theme/utils';

const WrapperBanner = styled(Box)`
  background-image: none;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  ${mediaQueries.xl} {
    background-image: url('/assets/images/smart-match-value-claim-exp2.png');
  }
`;

export const HeaderBanner = ({
  onClickClaim,
}: {
  onClickClaim: () => void;
}) => (
  <Box sx={{ width: '100%' }}>
    <WrapperBanner
      data-test-id="header-banner-claim-your-talent"
      borderRadius="large"
      sx={{
        backgroundColor: swagPalette.violetLight80,
      }}
      mt="xxxlarge"
      ml="large"
      mr="large"
      p="large"
      pl="xxxlarge"
    >
      <Typography.Title>
        Join the revolution - don&apos;t get left behind.
      </Typography.Title>
      <Typography.Text
        sx={{ mt: 'small', mb: 'medium' }}
        fontSize={16}
        fontWeight="light"
      >
        Businesses like yours are soaring to new heights with SmartMatch.
      </Typography.Text>
      <Button
        text="Claim your talent"
        onClick={onClickClaim}
        size="large"
        sx={{ pl: 'xxlarge', pr: 'xxlarge' }}
        data-test-id="claim-your-talent-button-header-banner"
      />
    </WrapperBanner>
  </Box>
);
