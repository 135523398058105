import styled from 'styled-components';

import { Button, Typography } from '@hero-design/react';

import OtherRoleCardWrapper from './OtherRoleCardWrapper';

import { onClickOpenDetailsFormProps } from '..';

const Wrapper = styled(OtherRoleCardWrapper)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.palette.violetLight90};
  justify-content: flex-end;
  padding: ${({ theme }) => `${theme.space.medium}px`};
  gap: ${({ theme }) => `${theme.space.medium}px`};
`;

const NeedAnotherRoleCard = ({
  onClickOpenDetailsForm,
}: {
  onClickOpenDetailsForm: (params: onClickOpenDetailsFormProps) => void;
}) => (
  <Wrapper>
    <Typography.Title level={3}>Have another role in mind?</Typography.Title>
    <Typography.Text intent="subdued">
      Customise your team. Let us know your requirements and we’ll match you to
      the perfect talent for any role.
    </Typography.Text>

    <Button
      text={'Get started'}
      variant="filled"
      intent="primary"
      size="large"
      sx={{
        width: '150px',
        fontWeight: 'bold',
      }}
      onClick={() => onClickOpenDetailsForm({ clickArea: 'Need Another Role' })}
    />
  </Wrapper>
);

export default NeedAnotherRoleCard;
