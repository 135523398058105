import noop from 'lodash/fp/noop';

import React from 'react';
import styled from 'styled-components';

import { swagPalette } from '@hero-design/colors';
import { Banner, Box, Button, Modal, Typography } from '@hero-design/react';

import { mediaQueries } from '@packages/hero-theme/utils';

import { Nullable } from 'src/modules/ReferenceCheck/common/types';

import { DeletionClaimProfileTrack } from '../CareersPageDetail/ClaimYourProfileExperiment/DeletionTrack';
import DetailsFormModal from '../CareersPageDetail/ClaimYourProfileExperiment/DetailsForm';
import {
  TDetailsForm,
  TrackEventViaServiceProps,
} from '../CareersPageDetail/ClaimYourProfileExperiment/type';
import { RecruitmentSettings } from '../../types';

import CycListCandidate from './CycListCandidate';
import { MatchCandidateItem } from './CycListCandidate/type';
import { HeaderBanner } from './HeaderBanner';
import OtherRolesSection from './OtherRolesSection';
import Profile from './Profile';

import {
  CYC_EPX_2_OPEN_DETAILS_USER_FORM,
  CYC_EPX_2_SUBMIT_PROFILE_CLAIM_FORM_EVENT,
} from './events';

const CycPageWrapper = styled.div`
  max-width: 100%;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQueries.xl} {
    max-width: 1700px;
  }
`;

const CustomBanner = styled(Banner)`
  padding-left: ${({ theme }) => theme.space.medium}px;
  ${mediaQueries.xl} {
    padding-left: ${({ theme }) => theme.space.xxxxlarge}px !important;
  }
`;

type CYCCareersPageDetailProps = {
  orgFriendlyId: string;
  isSendingTrackingEvent?: boolean;
  trackEventViaService: (params: TrackEventViaServiceProps) => void;
  recruitmentSettings: RecruitmentSettings | undefined;
};

export type onClickOpenDetailsFormProps = {
  candidateData?: Nullable<MatchCandidateItem>;
  clickArea: string;
};

const CYCCareersPageDetail = ({
  orgFriendlyId,
  isSendingTrackingEvent,
  trackEventViaService,
  recruitmentSettings,
}: CYCCareersPageDetailProps) => {
  const [openDetailsFormModal, setOpenDetailsFormModal] = React.useState(false);
  const [openThankyouModal, setOpenThankyouModal] = React.useState(false);
  const onClickOpenDetailsForm = ({
    candidateData,
    clickArea,
  }: onClickOpenDetailsFormProps) => {
    setOpenDetailsFormModal(true);
    trackEventViaService({
      eventName: CYC_EPX_2_OPEN_DETAILS_USER_FORM,
      candidateData,
      clickArea,
      industry: 'Hospitality',
    });
  };

  const handleTracking = (values: TDetailsForm) => {
    trackEventViaService({
      eventName: CYC_EPX_2_SUBMIT_PROFILE_CLAIM_FORM_EVENT,
      userDetails: values,
      industry: 'Hospitality',
    });

    setOpenDetailsFormModal(false);
    setOpenThankyouModal(true);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <CustomBanner
          intent="info"
          data-test-id="ghost-org-claim-banner-info"
          content={
            <Typography.Text>
              This talent page is a demo that can only be accessed by you. To
              view more of these candidate details, click on &quot;Claim your
              talent&quot; button now. If you wish to discard this page, click
              on the request link located at the footer of&nbsp;
              <a href="#claim-profile-footer">this page.</a>
            </Typography.Text>
          }
          sx={{
            p: 'medium',
            mb: 'medium',
            backgroundColor: swagPalette.aliceBlue,
          }}
        />
      </Box>
      <CycPageWrapper>
        <HeaderBanner
          onClickClaim={() =>
            onClickOpenDetailsForm({
              clickArea: 'Claim Your Talent On Header Banner',
            })
          }
        />
        {recruitmentSettings ? (
          <Profile
            recruitmentSettings={recruitmentSettings}
            orgFriendlyId={orgFriendlyId}
          />
        ) : null}
        <CycListCandidate onClickOpenDetailsForm={onClickOpenDetailsForm} />
        <OtherRolesSection onClickOpenDetailsForm={onClickOpenDetailsForm} />
        <Box mt="xxxlarge">
          <DeletionClaimProfileTrack
            trackEvent={trackEventViaService}
            isLoading={isSendingTrackingEvent}
            setIsShowClaimBanner={noop}
            isFromExp2
          />
        </Box>
      </CycPageWrapper>
      <DetailsFormModal
        onClose={() => setOpenDetailsFormModal(false)}
        open={openDetailsFormModal}
        handleTracking={values => handleTracking(values)}
        isLoading={isSendingTrackingEvent}
        isFromExp2
      />
      <Modal
        title="Thank you for Claiming your Talent!"
        variant="primary"
        onClose={() => setOpenThankyouModal(false)}
        open={openThankyouModal}
        body={
          <Typography.Text sx={{ textAlign: 'center' }}>
            Thank you for choosing SmartMatch. Your registration is now in
            process. In approximately 2 hours, you&apos;ll receive an email from
            Hiring Essentials with details on how to access and explore your
            curated pool of talent.
          </Typography.Text>
        }
        footer={
          <Button text="Close" onClick={() => setOpenThankyouModal(false)} />
        }
      />
    </>
  );
};

export default CYCCareersPageDetail;
