import React, { useMemo } from 'react';

import { Box } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { ValueOf } from '@shared/SampleQuestionnaire/common/types';

import { BLUR_DATA_URL } from 'src/constants';

export const OrgLogoSize = {
  lg: 'large',
  md: 'medium',
  sm: 'small',
  navBar: 'navBar',
} as const;

type OrgLogoProps = {
  orgLogoUrl: string;
  size?: ValueOf<typeof OrgLogoSize>;
};

const OrgLogo = ({ orgLogoUrl, size = OrgLogoSize.md }: OrgLogoProps) => {
  const logoSizePx = useMemo(() => {
    switch (size) {
      case OrgLogoSize.lg:
        return 160;
      case OrgLogoSize.md:
        return 120;
      case OrgLogoSize.sm:
        return 80;
      case OrgLogoSize.navBar:
        return 60;
      default: {
        const exhaustiveCheck: never = size;

        return exhaustiveCheck;
      }
    }
  }, [size]);

  return orgLogoUrl ? (
    <Box sx={{ alignSelf: size === OrgLogoSize.navBar ? 'center' : 'start' }}>
      <Box
        sx={{
          height: logoSizePx,
          width: logoSizePx,
          borderRadius: 'medium',
          position: 'relative',
          overflow: 'hidden',
          mr: 'medium',
          mb: 'small',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          data-test-id="org_logo_img"
          src={orgLogoUrl}
          alt="Organisation logo"
          layout="fill"
          objectFit="contain"
          quality={100}
          priority
          placeholder="blur"
          blurDataURL={BLUR_DATA_URL}
        />
      </Box>
    </Box>
  ) : null;
};

export default OrgLogo;
