import isEmpty from 'lodash/fp/isEmpty';

import React from 'react';

import { Box, Spinner, Typography } from '@hero-design/react';

import { InitFetchData } from '@shared/utils';

import { JobItem, RecruitmentSettings } from '../../types';

import RedesignRoleItem from '../shared/RedesignRoleItem';

type RoleListType = {
  jobsList: JobItem[];
  isLoading: boolean;
  recruitmentSettings: InitFetchData<RecruitmentSettings>;
};

const RoleList = ({
  jobsList,
  isLoading,
  recruitmentSettings,
}: RoleListType) => {
  const { recruitment_logo: companyLogo, company_name: companyName } =
    recruitmentSettings?.data ?? {};

  if (isLoading) {
    return <Spinner />;
  }

  if (isEmpty(jobsList)) {
    return <Typography.Text>No results found</Typography.Text>;
  }

  return (
    <Box sx={{ maxHeight: '40vh', overflow: 'auto', mt: 'large', mb: 'small' }}>
      {jobsList.map(role => (
        <RedesignRoleItem
          showStar
          data={role}
          key={role.id}
          isFromDefaultOrg
          companyName={companyName}
          companyLogo={companyLogo?.logo_url}
        />
      ))}
    </Box>
  );
};

export default RoleList;
