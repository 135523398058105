import React from 'react';
import styled, { useTheme } from 'styled-components';

import { swagPalette } from '@hero-design/colors';
import {
  Box,
  Button,
  Icon,
  IconProps,
  Tooltip,
  Typography,
} from '@hero-design/react';

import Avatar from '@packages/avatar';
import Image from '@packages/eh-adapter/image';

import { MatchCandidateItem } from './type';

import { onClickOpenDetailsFormProps } from '..';
import { DUMMY_CANDIDATE } from './constant';

const WrapperCandidateCard = styled(Box)`
  flex: 0 0 auto;
  width: 416px;
`;

const WrapperContentCard = styled(Box)<{ isDummy?: boolean }>`
  border-top: none;
  border: ${props =>
    props.isDummy ? 'none' : `1px solid ${props.theme.colors.defaultBorder}`};
  border-bottom-left-radius: ${({ theme }) => theme.radii.medium}px;
  border-bottom-right-radius: ${({ theme }) => theme.radii.medium}px;
`;

const sxPropsAvatarGroup = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  borderBottom: '1px solid',
  borderColor: 'defaultBorder',
  p: 'medium',
};

const sxPropsBtn = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pb: 'medium',
  pl: 'medium',
  pr: 'medium',
};

const sxPropsLocationGroup = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid',
  borderColor: 'defaultBorder',
  pt: 'small',
  pl: 'medium',
  pr: 'medium',
};

const DataItem = ({
  label,
  imgSrc,
  icon = 'diamond',
  isActiveText = true,
}: {
  label: string;
  imgSrc?: string;
  icon?: IconProps['icon'];
  isActiveText?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: theme.space.xsmall,
        pb: 'small',
      }}
    >
      {imgSrc ? (
        <Icon icon={<Image src={imgSrc} alt={`icon image ${label}`} />} />
      ) : (
        <Icon icon={icon} intent={isActiveText ? 'text' : 'subdued-text'} />
      )}
      <Typography.Text intent={isActiveText ? 'main' : 'subdued'} fontSize={12}>
        {label}
      </Typography.Text>
    </Box>
  );
};

export const CandidateCard = ({
  candidate,
  index,
  onClickOpenDetailsForm,
}: {
  candidate: MatchCandidateItem;
  index: number;
  onClickOpenDetailsForm: (params: onClickOpenDetailsFormProps) => void;
}) => {
  const candidateNameDisplay = `${candidate.first_name} ${
    candidate.last_name?.split('')[0]
  }.`;
  const theme = useTheme();

  const generateBackground = (idx: number) => {
    switch (idx) {
      case 0:
        return swagPalette.violetLight80;
      case 1:
        return swagPalette.violetLight50;
      case 2:
        return swagPalette.violetLight30;
      default:
        return swagPalette.violetLight80;
    }
  };

  return (
    <WrapperCandidateCard
      data-test-id={`card-candidate-index-${index}`}
      mb="large"
    >
      <Box
        sx={{
          backgroundColor: generateBackground(index),
          height: 70,
          width: '100%',
          borderTopLeftRadius: theme.radii.medium,
          borderTopRightRadius: theme.radii.medium,
        }}
      />
      <WrapperContentCard>
        <Box
          sx={{
            ...sxPropsAvatarGroup,
            mt: -theme.space.xxxlarge,
            gap: theme.space.medium,
          }}
        >
          <Box>
            <Box
              sx={candidate.avatar_url ? {} : { filter: 'blur(5px)' }}
              mb="medium"
            >
              <Avatar
                fullName={candidateNameDisplay}
                avatarURL={
                  candidate.avatar_url ||
                  '/assets/images/mask-avatar-placeholder.png'
                }
                avatarStyles={{
                  width: theme.sizes.xxxlarge,
                  height: theme.sizes.xxxlarge,
                }}
              />
            </Box>
            <Typography.Text>{candidateNameDisplay}</Typography.Text>
            <Typography.Text
              fontWeight="semi-bold"
              fontSize={16}
              sx={{ color: 'violet' }}
            >
              {candidate.current_job_title || ''}
            </Typography.Text>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              flexShrink: 0,
              textAlign: 'end',
              mt: -theme.space.large,
            }}
          >
            <Image
              src="/assets/images/top-match-img.svg"
              alt="top match image"
              sx={{ width: '110px' }}
            />
          </Box>
        </Box>

        <Box sx={sxPropsLocationGroup} pt="small" pl="medium" pr="medium">
          <Tooltip
            content="Sign up to view this candidate location"
            target={<DataItem label="Distance away" icon="lock-outlined" />}
          />
          <DataItem
            label="Relevant experience"
            imgSrc="/assets/images/suitcase-icon.svg"
          />
          {candidate.maybe_approachable ? (
            <DataItem
              label="Active recently"
              imgSrc="/assets/images/acute-icon.svg"
            />
          ) : (
            <Box sx={{ width: '30%' }} />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: theme.space.large,
            p: 'medium',
            pb: 'small',
          }}
        >
          <Box>
            <DataItem
              label="Work eligibility"
              icon={
                candidate.eligible_for_work
                  ? 'checkmark'
                  : 'circle-question-outlined'
              }
              isActiveText={candidate.eligible_for_work}
            />
            <Tooltip
              content="Sign up to view this candidate salary"
              target={<DataItem label="Salary" icon="lock-outlined" />}
            />
            <DataItem
              label={candidate.work_type ?? 'Full Time/ Part Time'}
              icon={
                candidate.work_type ? 'checkmark' : 'circle-question-outlined'
              }
              isActiveText={candidate.work_type !== null}
            />
            <DataItem
              label={
                candidate.availability_to_start
                  ? 'Available now'
                  : 'Notice period'
              }
              icon={
                candidate.availability_to_start
                  ? 'checkmark'
                  : 'circle-question-outlined'
              }
              isActiveText={candidate.availability_to_start !== null}
            />
          </Box>
          {candidate.certifications && (
            <DataItem
              label={`${candidate.certifications} certification`}
              icon="checkmark"
              isActiveText
            />
          )}
        </Box>
        <Box sx={sxPropsBtn}>
          <Button
            text={`Connect with ${candidate.first_name}`}
            size="large"
            variant="outlined"
            data-test-id="connect-with-candidate"
            sx={{
              width: '100%',
              borderColor: swagPalette.black,
              color: swagPalette.black,
              fontWeight: 'semi-bold',
            }}
            onClick={() =>
              onClickOpenDetailsForm({
                candidateData: candidate,
                clickArea: 'View Candidate Profile CTA',
              })
            }
          />
        </Box>
      </WrapperContentCard>
    </WrapperCandidateCard>
  );
};

export const DummyCandidateCard = ({
  onClickOpenDetailsForm,
}: {
  onClickOpenDetailsForm: (params: onClickOpenDetailsFormProps) => void;
}) => {
  const candidateNameDisplay = `${DUMMY_CANDIDATE.first_name} ${
    DUMMY_CANDIDATE.last_name?.split('')[0]
  }.`;

  const theme = useTheme();

  return (
    <Box sx={{ position: 'relative' }} data-test-id="dummy-candidate-card">
      <WrapperCandidateCard
        sx={{ filter: `blur(20px)`, width: '350px' }}
        mb="large"
      >
        <Box
          sx={{
            backgroundColor: swagPalette.violetLight80,
            height: 70,
            width: '100%',
            borderTopLeftRadius: theme.radii.medium,
            borderTopRightRadius: theme.radii.medium,
          }}
        />
        <WrapperContentCard isDummy>
          <Box
            sx={{
              ...sxPropsAvatarGroup,
              mt: -theme.space.xxxlarge,
              gap: theme.space.medium,
            }}
          >
            <Box sx={{ flexGrow: 1, flexShrink: 0 }}>
              <Box sx={{ mb: 'medium' }}>
                <Avatar
                  fullName={candidateNameDisplay}
                  avatarStyles={{
                    width: theme.sizes.xxxlarge,
                    height: theme.sizes.xxxlarge,
                  }}
                  avatarURL="/assets/images/mask-avatar-placeholder.png"
                />
              </Box>
              <Typography.Text>{candidateNameDisplay}</Typography.Text>
              <Typography.Text
                fontWeight="semi-bold"
                fontSize={18}
                sx={{ color: 'violet' }}
              >
                {DUMMY_CANDIDATE.current_job_title || ''}
              </Typography.Text>
            </Box>
          </Box>

          <Box sx={sxPropsLocationGroup}>
            <DataItem label="Distance away" icon="lock-outlined" />
            <DataItem
              label="Relevant experience"
              imgSrc="/assets/images/suitcase-icon.svg"
            />
            <DataItem
              label="Active recently"
              imgSrc="/assets/images/acute-icon.svg"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              gap: theme.space.xxlarge,
              p: 'medium',
              pb: 'small',
            }}
          >
            <Box>
              <DataItem label="Work eligibility" icon="checkmark" />
              <DataItem label="Salary" icon="lock-outlined" />
              <DataItem label="Full-time" icon="checkmark" />
              <DataItem label="Available now" icon="checkmark" />
            </Box>
          </Box>
          <Box sx={sxPropsBtn}>
            <Button
              text="Connect with candidate"
              data-test-id="connect-with-candidate"
              size="large"
              variant="outlined"
              sx={{
                width: '100%',
                borderColor: swagPalette.black,
                color: swagPalette.black,
                fontWeight: 'semi-bold',
              }}
            />
          </Box>
        </WrapperContentCard>
      </WrapperCandidateCard>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          text="Claim your talent"
          size="large"
          sx={{ fontWeight: 'semi-bold', pl: 'xxxlarge', pr: 'xxxlarge' }}
          onClick={() =>
            onClickOpenDetailsForm({
              clickArea: 'View More Candidate CTA',
            })
          }
        />
      </Box>
    </Box>
  );
};
