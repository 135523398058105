import React from 'react';

function useMergeState<T>(
  initialState: T
): [T, (newState: Partial<T> | React.SetStateAction<Partial<T>>) => void] {
  const [value, setValue] = React.useState<T>(initialState);
  const mergeState = React.useCallback(
    (newState: Partial<T> | React.SetStateAction<Partial<T>>) => {
      const isFunc = typeof newState === 'function';
      const updatedState = isFunc ? newState(value) : newState;

      setValue({ ...value, ...updatedState });
    },
    [value, setValue]
  );

  return [value, mergeState];
}

export default useMergeState;
