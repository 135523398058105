import React, { useMemo } from 'react';

import { Box, Button, Filters } from '@hero-design/react';

import { InitFetchData } from '@shared/utils';

import { DEFAULT_PAGE_INDEX } from 'src/constants';

import { useFetchOrgFilterOptions } from '../../../hooks/useFetchOrgFilterOptions';
import { CountriesList, QueryParams, TeamList } from '../../../types';

import BaseFilter from './BaseFilter';
import SalaryFilter from './SalaryFilter';

import SearchRoleBar from '../../CareersPageDetail/SearchRoleBar';
import {
  EmploymentTypeOptions,
  ExperienceLevelsOptions,
  WorkplaceTypeOptions,
} from './constants';

type MainFiltersProps = {
  orgId: string;
  queryParams: QueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<QueryParams>>;
  initFilterValues: {
    teams: InitFetchData<TeamList>;
    countries: InitFetchData<CountriesList>;
  };
  setCurrentSelectedPage: React.Dispatch<React.SetStateAction<number>>;
};

const MainFilters = ({
  orgId,
  queryParams,
  setQueryParams,
  initFilterValues,
  setCurrentSelectedPage,
}: MainFiltersProps) => {
  const {
    teamOptions,
    initDataDone,
    countryOptions,
    isLoadingTeams,
    isLoadingCountries,
  } = useFetchOrgFilterOptions({ orgId, initFilterValues });

  const initialValues = useMemo(() => queryParams, []);

  if (!initDataDone) {
    return null;
  }

  return (
    <Filters
      defaultValue={initialValues}
      value={queryParams}
      onChange={(value: QueryParams) =>
        setQueryParams({ ...value, page_index: 1 })
      }
      rightAddon={({ resetAll }) => (
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            text="Reset"
            size="medium"
            icon="restart"
            variant="text"
            onClick={() => {
              resetAll();
              setCurrentSelectedPage(DEFAULT_PAGE_INDEX);
            }}
            sx={{ color: 'black' }}
          />
          <SearchRoleBar
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            setCurrentSelectedPage={setCurrentSelectedPage}
          />
        </Box>
      )}
    >
      <BaseFilter
        filterKey="team_ids"
        options={teamOptions}
        filterLabel="Department"
        isLoading={isLoadingTeams}
      />

      <BaseFilter
        filterKey="employment_types"
        options={EmploymentTypeOptions}
        filterLabel="Work type"
      />

      <SalaryFilter queryParams={queryParams} setQueryParams={setQueryParams} />

      <BaseFilter
        filterKey="workplace_types"
        options={WorkplaceTypeOptions}
        filterLabel="On-site/Remote"
      />

      <BaseFilter
        filterKey="country_codes"
        options={countryOptions}
        filterLabel="Country"
        isLoading={isLoadingCountries}
      />

      <BaseFilter
        filterKey="experience_levels"
        options={ExperienceLevelsOptions}
        filterLabel="Experience level"
      />
    </Filters>
  );
};

export default MainFilters;
