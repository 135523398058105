export const CONTENT_TYPES = {
  HEADER: 'header',
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  GRID: 'grid',
  LIST: 'list',
  TESTIMONIAL: 'testimonial',
  PEOPLE: 'people',
  LOCATION: 'location',
  SOCIAL_LINK: 'social_link',
} as const;

export const STATUSES = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
  PUBLISHED_TO_CAREER_PAGE: 'published_to_career_page',
} as const;
