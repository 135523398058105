import isEqual from 'lodash/fp/isEqual';

import React, { useEffect, useMemo } from 'react';

import { Filters } from '@hero-design/react';

import RadioBaseFilter from '@shared/RadioBaseFilter';

import { QueryParams, SelectedValue } from '../../../types';

import { SalaryOptions } from './constants';

type SalaryFilterProps = {
  queryParams: QueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<QueryParams>>;
};

const SalaryFilter = ({ queryParams, setQueryParams }: SalaryFilterProps) => {
  const shouldHideSalary = useMemo(
    () => !isEqual(queryParams.country_codes)(['AU']),
    [queryParams.country_codes]
  );

  useEffect(() => {
    if (shouldHideSalary) {
      setQueryParams((prevQueryParams: QueryParams) => ({
        ...prevQueryParams,
        salary_min: 0,
      }));
    }
  }, [shouldHideSalary]);

  if (shouldHideSalary) {
    return null;
  }

  return (
    <Filters.Filter
      text="Salary"
      filterKey="salary_min"
      isApplyChangeOnClose={false}
    >
      {({ value, onChange, commit, closeDropdown }) => (
        <RadioBaseFilter
          dataTestId={{
            target: 'salary-min-filter-target',
            content: 'salary-min-filter-content',
          }}
          options={SalaryOptions}
          value={value as SelectedValue}
          onChange={(salary_min: SelectedValue) => {
            onChange(salary_min);
          }}
          commit={commit}
          closeDropdown={closeDropdown}
        />
      )}
    </Filters.Filter>
  );
};

export default SalaryFilter;
