import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';

import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Table } from '@hero-design/react';

import { mediaMaxQueries } from '@packages/hero-theme/utils';
import BaseFilter, { formatFilterValue } from '@shared/BaseFilter';

import useFetchCountriesListByOrgId from '../../hooks/useFetchCountriesListByOrgId';
import useFetchTeamsListByOrgId from '../../hooks/useFetchTeamsListByOrgId';
import {
  normalizeCountriesOptions,
  normalizeTeamsOptions,
} from '../../utils/helpers';
import {
  CountriesList,
  InitFetchData,
  QueryParams,
  SelectedValue,
  TeamList,
} from '../../types';

import SearchRoleBar from './SearchRoleBar';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.space.large}px;
  margin-bottom: ${({ theme }) => theme.space.large}px;

  ${mediaMaxQueries.sm} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  flex: 3;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`;

const SearchRoleBarWrapper = styled.div`
  flex: 1;
  width: 100%;
  ${mediaMaxQueries.md} {
    margin-top: ${({ theme }) => theme.space.small}px;
  }
`;

type FilterAndSearchBarProps = {
  orgId: string;
  queryParams: QueryParams;
  setQueryParams: (newState: React.SetStateAction<QueryParams>) => void;
  setCurrentSelectedPage: (newState: number) => void;
  initFilterValues: {
    countries: InitFetchData<CountriesList>;
    teams: InitFetchData<TeamList>;
  };
};

const { Filters, Filter } = Table.createFilters<QueryParams>();

const FilterAndSearchBar = ({
  orgId,
  queryParams,
  setQueryParams,
  setCurrentSelectedPage,
  initFilterValues,
}: FilterAndSearchBarProps) => {
  const { countries, teams } = initFilterValues;
  const [initDataDone, setInitDataDone] = useState(false);
  const { jobCountriesData, isLoading: isLoadingCountries } =
    useFetchCountriesListByOrgId(orgId, countries);
  const countryOptions = useMemo(
    () => (jobCountriesData ? normalizeCountriesOptions(jobCountriesData) : []),
    [jobCountriesData]
  );
  const { teamsData, isLoading: isLoadingTeams } = useFetchTeamsListByOrgId(
    orgId,
    teams
  );
  const teamOptions = useMemo(
    () => (teamsData ? normalizeTeamsOptions(teamsData) : []),
    [teamsData]
  );

  useEffect(() => {
    if (!isEmpty(countryOptions)) {
      setQueryParams(currentParams => ({
        ...currentParams,
        country_codes: map('value')(countryOptions),
      }));
    }
  }, [countryOptions]);

  useEffect(() => {
    if (!isEmpty(teamOptions)) {
      setQueryParams(currentParams => ({
        ...currentParams,
        team_ids: map('value')(teamOptions),
      }));
    }
  }, [teamOptions]);

  useEffect(() => {
    if (!isLoadingCountries && !isLoadingTeams) {
      setInitDataDone(true);
    }
  }, [isLoadingCountries, isLoadingTeams]);

  if (!initDataDone) {
    return null;
  }

  return (
    <Container>
      <FilterWrapper>
        <Filters value={queryParams} onChange={setQueryParams}>
          <Filter
            label="Department"
            formatValue={value => formatFilterValue(value, teamOptions)}
            filterKey="team_ids"
          >
            {({ value, onChange }) => (
              <BaseFilter
                options={teamOptions}
                value={value}
                onChange={(team_ids: SelectedValue[]) => {
                  setCurrentSelectedPage(1);
                  onChange(team_ids);
                }}
                loading={isLoadingTeams}
              />
            )}
          </Filter>

          <Filter
            label="Country"
            formatValue={value => formatFilterValue(value, countryOptions)}
            filterKey="country_codes"
          >
            {({ value, onChange }) => (
              <BaseFilter
                options={countryOptions}
                value={value}
                onChange={(country_codes: SelectedValue[]) => {
                  setCurrentSelectedPage(1);
                  onChange(country_codes);
                }}
                loading={isLoadingCountries}
              />
            )}
          </Filter>
        </Filters>
      </FilterWrapper>

      <SearchRoleBarWrapper>
        <SearchRoleBar
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          setCurrentSelectedPage={setCurrentSelectedPage}
        />
      </SearchRoleBarWrapper>
    </Container>
  );
};

export default FilterAndSearchBar;
