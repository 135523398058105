import isEmpty from 'lodash/fp/isEmpty';

import React, { useMemo } from 'react';

import { Result } from '@hero-design/react';
import { ResultProps } from '@hero-design/react/types/components/Result';

import LinkButton from '@shared/LinkButton';

import { ErrorData } from 'src/modules/CareersPage/types';

const getErrorMessage = ({
  errorData,
  customErrorMessage,
}: {
  errorData?: ErrorData;
  customErrorMessage: string;
}): string => {
  if (!isEmpty(customErrorMessage)) {
    return customErrorMessage;
  }

  return (
    errorData?.error?.errors?.[0]?.message ||
    'Something went wrong. Please try again later'
  );
};

type ErrorSlateProps = {
  title?: string;
  errorData?: ErrorData;
  customErrorMessage?: string;
  reloadUrl?: string;
  reloadText?: string;
} & Omit<ResultProps, 'title'>;

const ErrorSlate = ({
  title = 'Error',
  status = '500',
  errorData,
  reloadUrl,
  reloadText,
  customErrorMessage = '',
  ...others
}: ErrorSlateProps) => {
  const { reason, message } = useMemo(
    () => ({
      reason: `error: ${errorData?.error?.errors?.[0]?.reason}`,
      message: getErrorMessage({ errorData, customErrorMessage }),
    }),
    [errorData, customErrorMessage]
  );

  return (
    <Result
      status={status}
      title={title || reason}
      subTitle={message}
      extra={
        reloadUrl && (
          <LinkButton
            href={reloadUrl}
            variant="filled"
            intent="primary"
            text={reloadText || 'Reload'}
          />
        )
      }
      {...others}
    />
  );
};

export default ErrorSlate;
