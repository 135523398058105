import { useCallback, useState } from 'react';

import { Filters } from '@hero-design/react';

import MultiSelectBaseFilter from '@shared/MultiSelectBaseFilter';

import { SelectedValue, SelectOptions } from 'src/modules/CareersPage/types';

import { tagValueRendererCreator } from './utils';

const { Filter } = Filters;

type BaseFilterProps = {
  filterLabel: string;
  filterKey: string;
  options: SelectOptions;
  isLoading?: boolean;
};

const BaseFilter = ({
  options,
  filterKey,
  filterLabel,
  isLoading = false,
}: BaseFilterProps) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const handleSelectFilter = useCallback(
    (filter: string) => {
      if (!selectedFilters.includes(filter)) {
        setSelectedFilters([...selectedFilters, filter]);
      }
    },
    [selectedFilters]
  );

  if (options.length === 0) {
    return null;
  }

  return (
    <Filter
      text={filterLabel}
      filterKey={filterKey}
      isApplyChangeOnClose={false}
      tagValueRenderer={tagValueRendererCreator(options)}
    >
      {({ value, onChange, commit }) => (
        <MultiSelectBaseFilter
          dataTestId={{
            target: `${filterKey}-filter-target`,
            content: `${filterKey}-filter-content`,
          }}
          options={options}
          value={value as SelectedValue[]}
          onChange={(newSelectedValue: SelectedValue[]) => {
            onChange(newSelectedValue);
            handleSelectFilter(filterKey);
          }}
          commit={commit}
          loading={isLoading}
        />
      )}
    </Filter>
  );
};

export default BaseFilter;
