import { useTheme } from 'styled-components';

import { Box, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';

import OtherRoleCardWrapper from './OtherRoleCardWrapper';

export type OtherRoleCardProps = {
  imageUrl: string;
  role: string;
  description: string;
  candidates: string;
};

const OtherRoleCard = ({
  imageUrl,
  role,
  description,
  candidates,
}: OtherRoleCardProps) => {
  const theme = useTheme();

  return (
    <OtherRoleCardWrapper data-test-id="other-role-card">
      <Box
        sx={{
          width: '100%',
          height: '150px',
        }}
      >
        <Image
          src={imageUrl}
          alt={role}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>

      <Box
        mt="small"
        p="medium"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: theme.space.medium,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: theme.space.small,
          }}
        >
          <Typography.Title level={3}>{role}</Typography.Title>

          <Typography.Text intent="subdued">{description}</Typography.Text>
        </Box>

        <Typography.Text intent="subdued" tagName="span">
          <Typography.Text
            intent="subdued"
            tagName="span"
            sx={{
              fontSize: theme.sizes.xlarge,
              lineHeight: `${theme.sizes.xlarge}px`,
            }}
          >
            {candidates}
          </Typography.Text>{' '}
          candidates
        </Typography.Text>
      </Box>
    </OtherRoleCardWrapper>
  );
};

export default OtherRoleCard;
