import styled, { useTheme } from 'styled-components';

import { Box, Typography } from '@hero-design/react';

import NeedAnotherRoleCard from './NeedAnotherRoleCard';
import OtherRoleCard from './OtherRoleCard';

import { onClickOpenDetailsFormProps } from '..';
import { otherRolesData } from './constants';

const OtherRolesWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  box-sizing: border-box;

  padding-left: ${({ theme }) => theme.space.large}px;
  padding-right: ${({ theme }) => theme.space.large}px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const OtherRolesSection = ({
  onClickOpenDetailsForm,
}: {
  onClickOpenDetailsForm: (params: onClickOpenDetailsFormProps) => void;
}) => {
  const theme = useTheme();

  return (
    <Box
      mt="xxxlarge"
      sx={{
        alignSelf: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.space.medium,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        pl="large"
        pr="large"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.space.medium,
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Typography.Title level={1} fontWeight="semi-bold">
          Explore a world of hospitality talent
        </Typography.Title>

        <Typography.Text
          intent="subdued"
          fontSize={16}
          fontWeight="light"
          tagName="div"
          sx={{
            lineHeight: 'large',
            maxWidth: '700px',
          }}
        >
          Uncover a wealth of talent to elevate your hospitality business.
          SmartMatch connects you with the perfect individuals to enhance your
          team and exceed expectations
        </Typography.Text>
      </Box>

      <OtherRolesWrapper>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: theme.space.medium,
            minWidth: '1200px',
            maxWidth: '100%',
          }}
        >
          {otherRolesData.map(otherRoleProps => (
            <OtherRoleCard {...otherRoleProps} key={otherRoleProps.role} />
          ))}

          <NeedAnotherRoleCard
            onClickOpenDetailsForm={onClickOpenDetailsForm}
          />
        </Box>
      </OtherRolesWrapper>
    </Box>
  );
};

export default OtherRolesSection;
