import { useMemo } from 'react';
import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import {
  ExtendedErrorData,
  InitFetchData,
  JobsList,
  QueryParams,
} from '../types';

type JobsListResponse = {
  data: JobsList;
};

const useFetchJobsList = (
  orgId: string,
  isLoggedIn: boolean,
  queryParams?: QueryParams,
  initData?: InitFetchData<JobsList>,
  configs?: SWRConfiguration
) => {
  const initFetchData = initData?.data;
  // normalize to match with data shape of fetch
  const initJobsData = useMemo(
    () => initFetchData && { data: initFetchData },
    [initFetchData]
  );

  const fetchJobsUrl = isLoggedIn
    ? `${getAtsServiceDirectApiHost()}/api/v1/job_seeker/organisations/${orgId}/jobs`
    : `${getAtsServiceDirectApiHost()}/api/v1/career_page/organisations/${orgId}/jobs`;

  const { fetcher } = useFetch<
    JobsListResponse,
    QueryParams,
    ExtendedErrorData
  >({
    method: 'GET',
    endpoint: fetchJobsUrl,
    withSWR: true,
  });

  const {
    data,
    error: jobsListError,
    mutate: fetchJobsList,
    isValidating,
  } = useSWR<JobsListResponse, ExtendedErrorData>(
    orgId
      ? { key: `organisation_jobs_list_${orgId}`, params: queryParams }
      : null,
    ({ params }) => fetcher(params),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...configs,
      fallbackData: initJobsData,
    }
  );

  return {
    jobsListData: data,
    jobsListError,
    fetchJobsList,
    isFetchingJobsList: (!jobsListError && !data) || isValidating,
  };
};

export default useFetchJobsList;
