import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';

import { CountriesList } from '../types';

const OTHER_COUNTRIES_CODE = 'ZZ';

const getLocationName = (
  countryCode?: string | null,
  countryData?: { data: CountriesList }
) => {
  if (countryCode === OTHER_COUNTRIES_CODE || !countryData?.data?.items?.length)
    return '';
  return flow(find({ id: countryCode }), get('name'))(countryData?.data?.items);
};

export default getLocationName;
