import { useEffect, useMemo, useState } from 'react';

import {
  normalizeCountriesOptions,
  normalizeTeamsOptions,
} from '../utils/helpers';
import { CountriesList, InitFetchData, TeamList } from '../types';

import useFetchCountriesListByOrgId from './useFetchCountriesListByOrgId';
import useFetchTeamsListByOrgId from './useFetchTeamsListByOrgId';

type UseFetchOrgFilterOptionsProps = {
  orgId: string;
  initFilterValues: {
    teams: InitFetchData<TeamList>;
    countries: InitFetchData<CountriesList>;
  };
};

export const useFetchOrgFilterOptions = ({
  orgId,
  initFilterValues,
}: UseFetchOrgFilterOptionsProps) => {
  const [initDataDone, setInitDataDone] = useState(false);

  const { jobCountriesData, isLoading: isLoadingCountries } =
    useFetchCountriesListByOrgId(orgId, initFilterValues.countries);

  const { teamsData, isLoading: isLoadingTeams } = useFetchTeamsListByOrgId(
    orgId,
    initFilterValues.teams
  );

  const countryOptions = useMemo(
    () =>
      jobCountriesData?.data?.items.length
        ? normalizeCountriesOptions(jobCountriesData)
        : [],
    [jobCountriesData]
  );

  const teamOptions = useMemo(
    () =>
      teamsData?.data?.items.length ? normalizeTeamsOptions(teamsData) : [],
    [teamsData]
  );

  useEffect(() => {
    if (!isLoadingCountries && !isLoadingTeams) {
      setInitDataDone(true);
    }
  }, [isLoadingCountries, isLoadingTeams]);

  return {
    initDataDone,
    teamOptions,
    isLoadingTeams,
    countryOptions,
    isLoadingCountries,
  };
};
