import styled from 'styled-components';

const OtherRoleCardWrapper = styled.div`
  flex: 1;
  align-self: stretch;

  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radii.large}px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.palette.greyLight90};
`;

export default OtherRoleCardWrapper;
