import React from 'react';
import { useTheme } from 'styled-components';

import { Box, Icon, IconProps, Typography } from '@hero-design/react';

import { RecruitmentSettings } from '../../types';

type IconItemProps = {
  icon: IconProps['icon'];
  text: string;
};

const InfoItem = ({ text, icon }: IconItemProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mt: 'xsmall',
        display: 'flex',
        alignItems: 'center',
        gap: theme.space.small,
      }}
    >
      <Icon icon={icon} />
      <Typography.Text intent="body">{text}</Typography.Text>
    </Box>
  );
};

type CompanyInformationProps = {
  recruitmentSettingsData?: RecruitmentSettings;
};

const CompanyInformation = ({
  recruitmentSettingsData,
}: CompanyInformationProps) => {
  const {
    company_website: website,
    company_industry: industry,
    company_name: companyName,
    company_overview: companyOverview,
  } = recruitmentSettingsData || {};

  return (
    <Box
      sx={{
        mt: 'xxxlarge',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {companyName && (
        <Typography.Title level={1}>{companyName}</Typography.Title>
      )}

      {industry && (
        <InfoItem icon="double-buildings-outlined" text={industry} />
      )}

      {website && <InfoItem icon="link-2" text={website} />}

      {companyOverview && (
        <Typography.Text intent="body" tagName="p" sx={{ mt: 'medium' }}>
          {companyOverview}
        </Typography.Text>
      )}
    </Box>
  );
};

export default CompanyInformation;
