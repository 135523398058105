import React from 'react';

import { Box, Button, Modal, Typography } from '@hero-design/react';

import { TrackEventViaServiceProps } from './type';

import { CYC_EPX_2_REJECT_PROFILE_CLAIM } from '../../CYCCareersPageDetail/events';
import { REJECT_PROFILE_CLAIM_EVENT } from '..';

export const DeletionClaimProfileTrack = ({
  trackEvent,
  setIsShowClaimBanner,
  isLoading,
  isFromExp2,
}: {
  isLoading?: boolean;
  trackEvent: (params: TrackEventViaServiceProps) => void;
  setIsShowClaimBanner: (value: boolean) => void;
  isFromExp2?: boolean;
}) => {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleCloseThankyouModal = () => {
    setOpenSuccess(false);
    if (!isFromExp2) {
      setIsShowClaimBanner(false);
      localStorage.setItem('showClaimOrg', 'false');
    }

    window.open('https://swagapp.com/for-employers/', '_self');
  };

  return (
    <>
      <Box
        id="claim-profile-footer"
        data-test-id="claim-profile-footer"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          m: 'medium',
        }}
      >
        <Typography.Text>
          Not interested in claiming your career page? Request to discard
          it&nbsp;
          <Button
            text="here."
            variant="text"
            loading={isLoading}
            sx={{ p: 0, fontWeight: 'semi-bold' }}
            onClick={() => {
              trackEvent({
                eventName: isFromExp2
                  ? CYC_EPX_2_REJECT_PROFILE_CLAIM
                  : REJECT_PROFILE_CLAIM_EVENT,
              });

              setOpenSuccess(true);
            }}
          />
        </Typography.Text>
      </Box>
      <Modal.PopUp
        title="Your request has been sent successfully"
        open={openSuccess}
        variant="success"
        onClose={handleCloseThankyouModal}
        body="This career page will be deleted within the next 1-3 days."
        footer={<Button text="OK" onClick={handleCloseThankyouModal} />}
      />
    </>
  );
};
