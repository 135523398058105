import compact from 'lodash/fp/compact';
import concat from 'lodash/fp/concat';
import cond from 'lodash/fp/cond';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';

import { OTHER_OPTION } from '@shared/BaseFilter';

import { DEFAULT_ITEM_PER_PAGE } from 'src/constants';

import {
  AppliedJobItem,
  CountriesList,
  DataWrapper,
  IndustriesList,
  JobItem,
  QueryParams,
  RemoteSetting,
  SavedJobItem,
  SearchCareerPageJobItem,
  SelectOptions,
  Team,
  TeamList,
} from '../types';

import getLocationName from './getLocationName';

type onFilterChangeSWRType = {
  setQueryParams: (newState: React.SetStateAction<QueryParams>) => void;
  queryParams: QueryParams;
};

export const onFilterChangeSWR =
  ({ setQueryParams, queryParams }: onFilterChangeSWRType) =>
  (filterValueWithKey: QueryParams = {}) =>
    setQueryParams({
      ...queryParams,
      page_index: 1,
      item_per_page: DEFAULT_ITEM_PER_PAGE,
      ...filterValueWithKey,
    });

export const toFormData = (
  object: Record<string, unknown>,
  arrayObjectsFields?: string[]
) =>
  Object.keys(object).reduce((formData, key) => {
    if (includes(key)(arrayObjectsFields)) {
      (object[key] as unknown as Record<string, string>[]).forEach(item => {
        Object.keys(item).forEach(arrayObjectKey => {
          formData.append(
            `${key}[][${arrayObjectKey}]`,
            item[arrayObjectKey] === undefined ? '' : item[arrayObjectKey]
          );
        });
      });
    } else if (includes(key)(['resumes', 'cover_letters'])) {
      Object.keys(object[key] as unknown as Record<string, string>).forEach(
        objectItem => {
          formData.append(
            `${key}[${objectItem}]`,
            (object[key] as unknown as Record<string, string>)[objectItem] ===
              undefined
              ? ''
              : (object[key] as unknown as Record<string, string>)[objectItem]
          );
        }
      );
    } else formData.append(key, object[key] as string | Blob);
    return formData;
  }, new FormData());

export const normalizeCountriesOptions = <T = SelectOptions>(
  countriesData: DataWrapper<CountriesList>
): T =>
  flow(
    get('data.items'),
    map(item => ({
      text: get('name')(item),
      value: get('id')(item),
    })),
    sortBy('text')
  )(countriesData);

export const normalizeIndustriesOptions = <T = SelectOptions>(
  industriesData: DataWrapper<IndustriesList>
): T =>
  flow(
    get('data.items'),
    map(item => ({
      text: get('name')(item),
      value: get('id')(item),
    })),
    sortBy('text')
  )(industriesData);

export const normalizeTeamsOptions = (data: DataWrapper<TeamList>) =>
  flow(
    get('data.items'),
    cond([
      [
        (items: Team[]) => items?.length != null,
        flow(
          map(item => ({
            text: get('name')(item),
            value: get('id')(item),
          })),
          sortBy('text'),
          concat([OTHER_OPTION])
        ),
      ],
      [() => true, () => []],
    ])
  )(data) as unknown as SelectOptions;

export const getWorkplaceTypeText = (workplaceType: string) => {
  switch (workplaceType) {
    case 'remote_anywhere':
    case 'remote_domestic': {
      return 'Remote';
    }

    case 'onsite': {
      return 'On-site';
    }

    case 'hybrid': {
      return 'Hybrid';
    }

    default: {
      return workplaceType;
    }
  }
};

export const getRemoteSettingDescription = (
  remoteSetting?: RemoteSetting,
  countryList?: DataWrapper<CountriesList>
) => {
  if (!remoteSetting) return '';
  if (remoteSetting.anywhere) return `Anywhere`;
  if (remoteSetting.country_code)
    return `Within ${getLocationName(remoteSetting.country_code, countryList)}`;
  if (remoteSetting.timezone && !remoteSetting.tz_diff_hours)
    return `Within ${remoteSetting.timezone.replaceAll('_', ' ')}`;
  if (remoteSetting.timezone && remoteSetting.tz_diff_hours) {
    const diffHours = parseInt(remoteSetting.tz_diff_hours as string, 10);
    const diffHoursText =
      diffHours !== 1 ? `${diffHours} hours` : `${diffHours} hour`;
    const [timezone, utcOffset] = remoteSetting.timezone.split(' ');
    const timezoneText = `(UTC ${utcOffset}) ${timezone.replaceAll('_', ' ')}`;

    return `Within ${diffHoursText} of ${timezoneText}`;
  }

  return '';
};

export const getRemoteSettingsDescription = ({
  workplaceType,
  remoteSetting,
  rawCountriesData,
}: {
  workplaceType?: string;
  remoteSetting?: RemoteSetting;
  rawCountriesData?: DataWrapper<CountriesList>;
}) => {
  if (!workplaceType) return '';

  const workplaceTypeText = getWorkplaceTypeText(workplaceType);
  const remoteSettingDescription = getRemoteSettingDescription(
    remoteSetting,
    rawCountriesData
  );
  const remoteContent = compact([workplaceTypeText, remoteSettingDescription]);

  return ['', ...remoteContent].join(' • ');
};

export const getJobCreatedAt = (
  data: JobItem | SearchCareerPageJobItem | SavedJobItem | AppliedJobItem
) => {
  if ('created_at' in data) {
    return data.created_at;
  }

  if ('job_created_at' in data) {
    return data.job_created_at;
  }

  return null;
};

export const getJobAppliedAt = (
  data: JobItem | SearchCareerPageJobItem | SavedJobItem | AppliedJobItem
) => {
  if ('last_applied_at' in data) {
    return data.last_applied_at;
  }

  if ('applied_at' in data) {
    return data.applied_at;
  }

  return null;
};

export const formatCurrency = (amount: number, currency: string) =>
  amount.toLocaleString('en-US', {
    style: 'currency',
    currency: currency === 'EU' ? 'EUR' : currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
