import React from 'react';
import { useTheme } from 'styled-components';

import { Box } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';

import { RecruitmentSettings } from '../../types';

type HeaderProps = {
  recruitmentLogo?: RecruitmentSettings['recruitment_logo'];
};

const Header = ({ recruitmentLogo }: HeaderProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mt: 'small',
        mb: 'large',
        width: '100%',
        position: 'relative',
      }}
    >
      <Image
        alt="default-lifted-career-page-detail-background"
        src="/assets/images/default-lifted-header-background.png"
        style={{
          width: '100%',
          height: '140px',
          borderRadius: theme.radii.large,
        }}
      />

      {recruitmentLogo && (
        <Image
          src={recruitmentLogo.logo_url}
          alt="default-lifted-career-page-detail-logo"
          style={{
            left: '5%',
            height: '50%',
            bottom: '-20%',
            position: 'absolute',
            width: 'fit-content',
            borderRadius: theme.radii.medium,
          }}
        />
      )}
    </Box>
  );
};

export default Header;
