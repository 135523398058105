import { OtherRoleCardProps } from './OtherRoleCard';

export const otherRolesData: OtherRoleCardProps[] = [
  {
    imageUrl: '/assets/images/wait-staff.png',
    role: 'Kitchen Hands',
    description:
      'Ensure seamless kitchen operations with skilled hands dedicated to efficiency, allowing your culinary team to shine.',
    candidates: '2,700+',
  },
  {
    imageUrl: '/assets/images/cashier.png',
    role: 'Bartenders',
    description:
      'Exemplary service awaits. Find the ideal Bartender to elevate your Hospitality experience.',
    candidates: '1,800+',
  },
  {
    imageUrl: '/assets/images/chef.png',
    role: 'Chefs',
    description:
      'Culinary excellence is just a flick away. Connect with seasoned chefs to enhance your food offerings.',
    candidates: '2,600+',
  },
];
