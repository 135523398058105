import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

import { Box, Pagination, Spinner } from '@hero-design/react';

import { useFetch } from '@packages/eh-utils/api';
import { getUrlTrackingServiceDirectApiHost } from '@packages/eh-utils/browserEnv';
import { dynamicImport } from '@packages/eh-utils/dynamicImport';
import { decamelizeKeys } from '@packages/eh-utils/formatObject';
import ErrorSlate from '@shared/ErrorSlate';
import { ContentWrapper } from '@shared/PageLayout';
import { TPermissions } from '@shared/hooks/useFetchPermissions';
import {
  TRACK_ATS_SCAN_QR_POSTER_TO_CAREER_PAGE,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

import { TUtmData } from 'src/modules/User/types';
import { DEFAULT_ITEM_PER_PAGE } from 'src/constants';

import CYCCareersPageDetail from '../CYCCareersPageDetail';
import DefaultLiftedCareerPageDetail from '../DefaultLiftedCareerPageDetail';
import { STATUSES } from '../RedesignedCareersPageDetail/constants';
import useFetchJobsList from '../../hooks/useFetchJobsList';
import useFetchRecruitmentSettings from '../../hooks/useFetchRecruitmentSettings';
import { onFilterChangeSWR } from '../../utils/helpers';
import {
  CountriesList,
  InitFetchData,
  JobsList,
  LandingPage,
  QueryParams,
  RecruitmentSettings,
  TeamList,
  TOrganisationPermisions,
} from '../../types';

import {
  TrackEventParams,
  TrackEventViaServiceProps,
} from './ClaimYourProfileExperiment/type';
import { prepareTrackingParams } from './ClaimYourProfileExperiment/utils';
import FilterAndSearchBar from './FilterAndSearchBar';
import Overview from './Overview';

import { LANDING_PAGE_TYPES } from '../../../../shared/OrganisationPageLayout/constants';
import RolesList from '../shared/RoleList';

export const RedesignedCareerPageDetail = dynamicImport(
  () => import('../RedesignedCareersPageDetail'),
  { ssr: false }
);

export const SUBMIT_PROFILE_CLAIM_FORM_EVENT =
  'Ghost Career: Submit Profile Claim';
export const CLICK_PROFILE_CLAIM_BUTTON_EVENT =
  'Ghost Career: Click Profile Claim Button';
export const REJECT_PROFILE_CLAIM_EVENT = 'Ghost Career: Reject Profile Claim';

type CurrentCareersPageDetailProps = {
  orgId: string;
  jobs: InitFetchData<JobsList>;
  countries: InitFetchData<CountriesList>;
  teams: InitFetchData<TeamList>;
  recruitmentSettings: InitFetchData<RecruitmentSettings>;
};

type CareersPageDetailProps = {
  landingPage: InitFetchData<LandingPage>;
  previewId: string | null;
  permissions?: TPermissions;
  organisationPermissions: InitFetchData<TOrganisationPermisions>;
} & CurrentCareersPageDetailProps;

const CurrentCareersPageDetail: NextPage<CurrentCareersPageDetailProps> = ({
  orgId,
  jobs,
  countries,
  teams,
  recruitmentSettings: orgData,
}) => {
  const { track } = useMixpanelTracking();
  const router = useRouter();
  const [queryParams, setQueryParams] = useState<QueryParams>({
    item_per_page: DEFAULT_ITEM_PER_PAGE,
    page_index: 1,
    country_codes: [],
    team_ids: [],
  });
  const { status } = useSession();
  const { jobsListData, jobsListError, isFetchingJobsList } = useFetchJobsList(
    orgId,
    status === 'authenticated',
    queryParams,
    jobs,
    { revalidateIfStale: true }
  );
  const { recruitmentSettings, isFetchingRecruitmentSettings } =
    useFetchRecruitmentSettings(orgId, orgData);
  const [currentSelectedPage, setCurrentSelectedPage] = useState(1);

  const dataReady = useMemo(() => !isNil(jobsListData), [jobsListData]);

  const hasNoJobs = useMemo(
    () => dataReady && flow(get('data.items'), isEmpty)(jobsListData),
    [dataReady, jobsListData]
  );
  const onChangePage = useCallback(
    (selectedPage: number) => {
      setCurrentSelectedPage(selectedPage);
      onFilterChangeSWR({
        queryParams,
        setQueryParams,
      })({ page_index: selectedPage });
    },
    [queryParams]
  );

  useEffect(() => {
    if (router.query.utm_medium === 'qr') {
      track(TRACK_ATS_SCAN_QR_POSTER_TO_CAREER_PAGE);
    }
  }, [router.query.utm_medium]);

  const jobsList = jobsListData?.data?.items ? jobsListData?.data?.items : [];
  const totalItems = jobsListData?.data?.total_items
    ? jobsListData.data.total_items
    : 0;
  const totalPages = jobsListData?.data?.total_pages
    ? jobsListData.data.total_pages
    : 0;

  if (isFetchingRecruitmentSettings) {
    return (
      <ContentWrapper>
        <Spinner />
      </ContentWrapper>
    );
  }

  if (jobsListError) {
    switch (jobsListError.status) {
      case 404:
        return (
          <ContentWrapper>
            <ErrorSlate
              title="Oops"
              status="404"
              customErrorMessage="This career page does not currently exist."
              errorData={jobsListError?.info}
              reloadUrl="/"
              reloadText="Find more roles"
            />
          </ContentWrapper>
        );

      default:
        return (
          <ContentWrapper>
            <ErrorSlate
              title={
                jobsListError?.status ? jobsListError?.status.toString() : '404'
              }
              status="404"
              errorData={jobsListError?.info}
            />
          </ContentWrapper>
        );
    }
  }

  return (
    <ContentWrapper>
      {recruitmentSettings?.data && (
        <Overview data={recruitmentSettings.data} totalJobs={totalItems} />
      )}

      <FilterAndSearchBar
        orgId={orgId}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setCurrentSelectedPage={setCurrentSelectedPage}
        initFilterValues={{
          countries,
          teams,
        }}
      />

      {hasNoJobs ? (
        <>No results found</>
      ) : (
        <>
          {isFetchingJobsList ? (
            <Spinner />
          ) : (
            <>
              <RolesList
                data={jobsList}
                companyLogo={
                  recruitmentSettings?.data?.recruitment_logo?.logo_url
                }
                companyName={recruitmentSettings?.data?.company_name}
              />

              {totalItems > DEFAULT_ITEM_PER_PAGE && (
                <Box
                  mt="large"
                  mb="xxxxlarge"
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Pagination
                    current={currentSelectedPage}
                    total={totalPages}
                    onChange={onChangePage}
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </ContentWrapper>
  );
};

const CareersPageDetail: NextPage<CareersPageDetailProps> = ({
  orgId,
  jobs,
  countries,
  teams,
  recruitmentSettings,
  landingPage,
  previewId,
  permissions,
  organisationPermissions,
}) => {
  const { getDistinctId } = useMixpanelTracking();
  const router = useRouter();

  const isPublished =
    landingPage?.data?.status === STATUSES.PUBLISHED_TO_CAREER_PAGE;
  const isPreview = !isEmpty(previewId);
  const contentBlocks = landingPage?.data?.content_blocks || [];
  const visibleContentBlocks = contentBlocks.filter(
    ({ visible_on_page }) => visible_on_page
  );
  const defaultLiftedCareerPageDetailEnabled =
    permissions?.revamp_default_company_page_enabled;

  const { fetcher: trackEvent, loading: isSendingTrackingEvent } = useFetch<
    void,
    TrackEventParams
  >({
    endpoint: `${getUrlTrackingServiceDirectApiHost()}/api/v1/events`,
    method: 'POST',
    formatBody: params => JSON.stringify(decamelizeKeys(params)),
  });

  const utmData: TUtmData = {
    utm_campaign: router.query.utm_campaign,
    utm_source: router.query.utm_source,
    utm_medium: router.query.utm_medium,
    utm_content: router.query.utm_content,
    utm_term: router.query.utm_term,
  };

  const trackEventViaService = async ({
    eventName,
    userDetails,
    candidateData,
    industry,
    clickArea,
  }: TrackEventViaServiceProps) => {
    const submitParams = prepareTrackingParams({
      distinctId: getDistinctId(),
      userDetails,
      eventName,
      utmData,
      orgId,
      candidateData,
      industry,
      clickArea,
    });

    await trackEvent(submitParams);
  };

  const isProfileClaimed = recruitmentSettings?.data?.eh_profile_claimed;

  const showClaimedCareerPage =
    defaultLiftedCareerPageDetailEnabled && isProfileClaimed === true;

  const ghostOrgExperimentV2 =
    organisationPermissions?.data?.ghost_career_page_experiment_v2_enabled;
  const isUnclaimedOrg = !!ghostOrgExperimentV2 && isProfileClaimed === false;
  const hasVisibleContentBlocks =
    (isPublished || isPreview) && visibleContentBlocks.length > 0;

  const shouldShowRedesignedPage = hasVisibleContentBlocks || isUnclaimedOrg;
  const shouldShowCYCCareersPage =
    landingPage.data?.type === LANDING_PAGE_TYPES.TALENT_LANDING_PAGE;

  if (shouldShowCYCCareersPage) {
    return (
      <CYCCareersPageDetail
        orgFriendlyId={orgId}
        recruitmentSettings={recruitmentSettings.data}
        isSendingTrackingEvent={isSendingTrackingEvent}
        trackEventViaService={trackEventViaService}
      />
    );
  }

  if (shouldShowRedesignedPage) {
    return (
      <RedesignedCareerPageDetail
        jobs={jobs}
        orgFriendlyId={orgId}
        initLandingPage={landingPage}
        isUnclaimedOrg={isUnclaimedOrg}
        recruitmentSettings={recruitmentSettings}
        organisationPermissions={organisationPermissions}
        isLoading={isSendingTrackingEvent}
        trackEventViaService={trackEventViaService}
      />
    );
  }

  if (showClaimedCareerPage) {
    return (
      <DefaultLiftedCareerPageDetail
        jobs={jobs}
        orgId={orgId}
        teams={teams}
        countries={countries}
        recruitmentSettings={recruitmentSettings}
      />
    );
  }

  return (
    <CurrentCareersPageDetail
      jobs={jobs}
      orgId={orgId}
      teams={teams}
      countries={countries}
      recruitmentSettings={recruitmentSettings}
    />
  );
};

export default CareersPageDetail;
