import React from 'react';
import styled from 'styled-components';

import { Box, Typography } from '@hero-design/react';

import { mediaQueries } from '@packages/hero-theme/utils';

import { CandidateCard, DummyCandidateCard } from './CandidateCard';

import { onClickOpenDetailsFormProps } from '..';
import { LIST_CANDIDATES } from './constant';

export const CycListCandidateWrapper = styled(Box)`
  width: 100%;
  max-width: 100vw;
`;

export const WrapperCandidateList = styled(Box)`
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.space.medium}px;
  flex-wrap: no-wrap;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const WrapperContainer = styled.div`
  padding: 0 0 0 ${({ theme }) => theme.space.large}px;
  ${mediaQueries.xl} {
    padding: 0 ${({ theme }) => theme.space.large}px;
  }
`;

const CycListCandidate = ({
  onClickOpenDetailsForm,
}: {
  onClickOpenDetailsForm: (params: onClickOpenDetailsFormProps) => void;
}) => (
  <CycListCandidateWrapper mt="large" data-test-id="candidates-list">
    <Typography.Title
      sx={{ mt: 'medium', mb: 'medium', pl: 'large', pr: 'large' }}
    >
      Discover top talent around you
    </Typography.Title>
    <Typography.Text
      sx={{
        mt: 'medium',
        mb: 'medium',
        pl: 'large',
        pr: 'large',
        maxWidth: 600,
      }}
      intent="subdued"
      fontSize={16}
      fontWeight="light"
    >
      Explore the profiles of outstanding Hospitality Talent near you. These
      skilled candidates are ready to bring their expertise to your business.
    </Typography.Text>

    <WrapperContainer>
      <WrapperCandidateList>
        {LIST_CANDIDATES.map((candidate, idx) => (
          <CandidateCard
            candidate={candidate}
            key={candidate.id}
            index={idx}
            onClickOpenDetailsForm={onClickOpenDetailsForm}
          />
        ))}
        <DummyCandidateCard onClickOpenDetailsForm={onClickOpenDetailsForm} />
      </WrapperCandidateList>
    </WrapperContainer>
  </CycListCandidateWrapper>
);

export default CycListCandidate;
