import React, { useCallback, useState } from 'react';
import { useSession } from 'next-auth/react';

import { Typography } from '@hero-design/react';

import ErrorSlate from '@shared/ErrorSlate';
import { InitFetchData } from '@shared/utils';

import { DEFAULT_ITEM_PER_PAGE, DEFAULT_PAGE_INDEX } from 'src/constants';

import useFetchJobsList from '../../hooks/useFetchJobsList';
import { onFilterChangeSWR } from '../../utils/helpers';
import {
  CountriesList,
  JobsList,
  QueryParams,
  RecruitmentSettings,
  TeamList,
} from '../../types';

import RoleList from './RoleList';

import MainFilters from '../shared/MainFilters';
import PaginationSection from '../shared/PaginationSection';

type RoleListContainerProps = {
  orgId: string;
  jobs: InitFetchData<JobsList>;
  teams: InitFetchData<TeamList>;
  countries: InitFetchData<CountriesList>;
  recruitmentSettings: InitFetchData<RecruitmentSettings>;
};

const RoleListContainer = ({
  jobs,
  orgId,
  teams,
  countries,
  recruitmentSettings,
}: RoleListContainerProps) => {
  const { status } = useSession();

  const [currentSelectedPage, setCurrentSelectedPage] =
    useState(DEFAULT_PAGE_INDEX);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page_index: DEFAULT_PAGE_INDEX,
    item_per_page: DEFAULT_ITEM_PER_PAGE,
  });

  const onChangePage = useCallback(
    (selectedPage: number) => {
      setCurrentSelectedPage(selectedPage);
      onFilterChangeSWR({ queryParams, setQueryParams })({
        page_index: selectedPage,
      });
    },
    [queryParams, setQueryParams, setCurrentSelectedPage]
  );

  const { jobsListData, isFetchingJobsList, jobsListError } = useFetchJobsList(
    orgId,
    status === 'authenticated',
    queryParams,
    jobs,
    { revalidateIfStale: true }
  );

  const totalItems = jobsListData?.data.total_items ?? 0;
  const totalPages = jobsListData?.data.total_pages ?? 0;
  const companyName = recruitmentSettings?.data?.company_name;

  if (jobsListError) {
    switch (jobsListError.status) {
      case 404:
        return (
          <ErrorSlate
            title="Oops"
            status="404"
            customErrorMessage="This career page does not currently exist."
            errorData={jobsListError?.info}
            reloadUrl="/"
            reloadText="Find more roles"
          />
        );

      default:
        return (
          <ErrorSlate
            title={
              jobsListError?.status ? jobsListError?.status.toString() : '404'
            }
            status="404"
            errorData={jobsListError?.info}
          />
        );
    }
  }

  return (
    <>
      <Typography.Text
        fontSize={18}
        sx={{ mb: 'medium' }}
        fontWeight="semi-bold"
      >
        Available roles {companyName && `at ${companyName}`}
      </Typography.Text>

      <MainFilters
        orgId={orgId}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        initFilterValues={{ countries, teams }}
        setCurrentSelectedPage={setCurrentSelectedPage}
      />

      <RoleList
        isLoading={isFetchingJobsList}
        jobsList={jobsListData?.data?.items ?? []}
        recruitmentSettings={recruitmentSettings}
      />

      <PaginationSection
        totalPages={totalPages}
        totalItems={totalItems}
        onChangePage={onChangePage}
        currentSelectedPage={currentSelectedPage}
      />
    </>
  );
};

export default RoleListContainer;
